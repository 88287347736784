@use '/src/styles/colors.scss' as *;

.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: $default-gray-700;
}

.folderHeader {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.folderLeft {
  display: flex;
  align-items: center;
  gap: 5px;
  cursor: pointer;
}

.button {
  padding: 0px 13px;
  background-color: $default-gray-600;
  border-radius: 5px;
  cursor: pointer;
}

.addNewFolder {
  border-top: 1px solid $default-gray-500;
  padding: 10px 0 0;
  margin-bottom: 16px;
  display: flex;
  gap: 12px;
  align-items: center;
  cursor: pointer;
}

.folderItemsWrapper {
  display: flex;
  flex-direction: column;
  gap: 12px;
  background-color: $default-gray-700;
  padding: 4px;
}

.unitBlock {
  display: flex;
  align-items: center;
  gap: 12px;
}

.input {
  & div:global.MuiInputBase-input-MuiOutlinedInput-input {
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
    padding: 0px 8px;
  }
}

.title {
  display: flex;
  flex-direction: column;
}

.newFolderInputWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
  gap: 5px;
}

.newFolderInput {
  width: 100%;
  font-family: Poppins;
  color: $white;
  font-size: 14px;
  height: 28px;
  background-color: $default-gray-700;
  border: 1px solid $HEXB8FE61;
  border-radius: 14px;
  padding: 4px 10px;
  outline: none;
}

.newFolderInput:focus {
  border-color: $HEXB8FE61;
}
