@use '/src/styles/colors.scss' as *;

.cell {
  padding: 12px 0px 16px 0px;
  border-bottom: 1px solid $default-gray-700;
}

.sortLabel {
  display: inline-flex;
  align-items: center;
  gap: 6px;

  & svg:global.MuiTableSortLabel-icon {
    opacity: 100;
  }

  &:hover svg:global.MuiTableSortLabel-icon {
    opacity: 100;
  }
}
