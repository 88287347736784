@use '/src/styles/colors.scss' as *;

.container {
  display: flex;
  align-items: center;
  gap: 8px;
}

.image {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  object-fit: contain;
  color: $default-gray-200;
}
