@use "/src/styles/colors.scss" as *;

.wrapper {
  display: flex;
  align-items: center;
  gap: 10px;
}

.root {
  width: 44px;
  height: 24px;
  padding: 0;
}

.switchBase {
  padding: 2px;
  color: $default-gray-100;
  &:global.Mui-checked {
    color: $default-gray-100;
    &::after {
      content: url("/assets/icons/check.svg");
      top: 1px;
      left: 6px;
      position: absolute;
    }
  }
}

.track {
  border-radius: 12px;
  background-color: $default-gray-400;
}

.checked {
  &:global.MuiSwitch-switchBase + .MuiSwitch-track {
    opacity: 1;
    background: linear-gradient(to left, $gr-landing);
    background-color: $default-gray-400;
  }
}
