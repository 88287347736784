@use '/src/styles/colors.scss' as *;

.wrapper {
  width: 100%;
  border-radius: 16px;
  background-color: $bg-color;
  padding: 32px 40px;

  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 64px;
}

.location {
  display: flex;
  gap: 12px;
}
