@use '/src/styles/colors.scss' as *;

$scrollbar-width: 6px;
$scrollbar-border-radius: 20px;

.statesDropdown {
  background-color: $default-gray-700;

  ul {
    scrollbar-width: thin;
    scrollbar-color: $default-gray-500 transparent;

    &::-webkit-scrollbar {
      width: $scrollbar-width;
      border-radius: $scrollbar-border-radius;
    }

    &::-webkit-scrollbar-thumb {
      background-color: $default-gray-500;
      border-radius: $scrollbar-border-radius;
    }
  }
}

.state {
  margin: 4px 2px;
  padding: 2px 8px;

  display: flex;
  gap: 8px;

  border-radius: 8px;

  &:hover {
    cursor: pointer;
    background-color: $default-gray-600;
  }

  &[aria-selected='true'] {
    font-weight: 500;
    color: $default-gray-900;
    background: linear-gradient(270deg, $gr-landing);
  }
}

.stateAbbreviation {
  color: $default-gray-400;
  width: 24px;
  text-align: center;
  vertical-align: middle;
}
