@use '/src/styles/colors.scss' as *;

.calendar {
  border-radius: 12px;
  background-color: $bg-color;
  border-color: $bg-color;
  color: white;
  left: 1338px;
}

.calendar > * > * {
  margin: 10px;
}

.calendarIcon {
  width: 24px;
  height: 24px;
  margin-right: 12px;
  color: $default-gray-300;
}

.arrowContainer {
  width: 20px;
  height: 20px;
  margin-left: 12px;
  display: inline-flex;
  transition: transform 0.3s ease;
  margin-bottom: 2px;

  &.open {
    transform: rotate(180deg);
  }
}

.iconContainer {
  width: 24px;
  height: 24px;
  display: inline-flex;
  margin-right: 12px;
}

.binIcon {
  width: 24px;
  height: 24px;
  margin-right: 12px;
}

.arrowButton {
  color: $HEXB8FE61;
}

.button {
  &.active {
    background: linear-gradient(270deg, $gr-landing);
    color: $default-gray-900;
  }
}
