@use '/src/styles/colors.scss' as *;

.scheduleContainer {
  margin: 12px 0px;
  padding: 17px 0px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.scheduleTitle {
  display: flex;
  align-items: center;
  gap: 12px;
}

.scheduleAnchor {
  padding: 10px 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.clockIcon {
  display: flex;
  align-items: center;
  justify-content: center;
  background: -webkit-linear-gradient(135deg, $gr-landing);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.openClosed {
  display: flex;
  align-items: center;
  gap: 15px;
}

.schedulePopover {
  border-radius: 12px;
  background-color: $default-gray-700;
}

.scheduleDays {
  padding: 8px 24px;
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.oneScheduleDay {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
