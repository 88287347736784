@use '/src/styles/colors.scss' as *;
@use '/src/styles/mixins.scss' as flexbox;

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
}

.uniqueImageCard {
  position: relative;
  top: 10px;
  @include flexbox.flexbox-center;
  padding: 3px 8px 8px;
  border-radius: 200px;
  background: linear-gradient(to left, $gr-landing);
  z-index: -1;
}

.uniqueImage {
  width: 19.5px;
  height: 24.98px;
}

.uniqueText {
  font-size: 8px;
  font-weight: 500;
  line-height: 8px;
}

.textBackground {
  display: inline-flex;
  padding: 5px 12px 4px 12px;
  border-radius: 20px;
  background-color: #767575;
  z-index: 1;
}

.backgroundBorder {
  position: absolute;
  bottom: -1px;
  left: 0px;
  z-index: -1;
  height: 17px;
  width: 87px;
  border-radius: 20px;
  background: linear-gradient(42deg, #39b04a 43%, #d4df28 100%);
}

.imageWrapper {
  display: flex;
  flex-direction: column;
}

.basicImage {
  height: 30px;
}

.basicText {
  font-size: 6px;
  font-weight: 500;
  line-height: 8px;
}

.rate {
  position: absolute;
  left: 37px;
  bottom: 2px;
}
