@use '/src/styles/colors.scss' as *;

.description {
  color: $default-gray-200;
}

.email {
  color: $HEXB8FE61;
}

.container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: inherit;
}

.fields {
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: inherit;
}
