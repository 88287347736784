@use '/src/styles/colors.scss' as *;

.wrapper {
  display: flex;
  flex-wrap: wrap;
  background-color: $bg-color;
  border-radius: 16px;
  margin-top: 24px;
}

.chartWrapper {
  width: 100%;
}
