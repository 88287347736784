@use '/src/styles/colors.scss' as *;

$border-radius: 8px;
$scrollbar-width: 6px;
$scrollbar-border-radius: 20px;

.dropdown {
  padding: 8px;
  margin-top: 24px;
  width: 100%;
  max-width: 271px;
  max-height: 346px;
  background-color: $default-gray-700;

  scrollbar-width: thin;
  scrollbar-color: $default-gray-500 transparent;

  &::-webkit-scrollbar {
    width: $scrollbar-width;
    border-radius: $scrollbar-border-radius;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $default-gray-500;
    border-radius: $scrollbar-border-radius;
  }

  & ul:global.MuiList-root {
    display: flex;
    flex-direction: column;
    gap: 12px;
  }
}

.dropdownItemWrapper {
  padding: 0;
  border: 1px solid transparent;
  border-radius: $border-radius;
  background: linear-gradient(transparent, transparent) padding-box,
    linear-gradient(45deg, $landing-green2, $landing-green1) border-box;
}

.dropdownItem {
  width: 100%;
  height: 100%;

  display: flex;
  align-items: center;
  gap: 8px;

  padding: 8px 16px;
  border-radius: $border-radius;
  background-color: $bg-color;
  line-height: 20px;

  &:hover {
    p {
      color: $bg-color;
    }

    background: linear-gradient(
      291deg,
      $landing-green1 -14.1%,
      $landing-green2 106.43%
    );
  }
}

.selectedItem {
  p {
    color: $bg-color;
  }

  background: linear-gradient(
    291deg,
    $landing-green1 -14.1%,
    $landing-green2 106.43%
  );
}

.blurredWrapper {
  pointer-events: none;
  user-select: none;
}

.itemLabel {
  word-wrap: break-all;
  white-space: normal;
  line-height: 20px;
}
