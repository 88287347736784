@use "/src/styles/colors.scss" as *;

.chartContainer {
  position: relative;
  flex-grow: 1;
  padding: 24px 30px 24px 16px;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 7px;
  padding-right: 10px;
  margin-bottom: 23px;
}

.buttonsChart {
  display: flex;
  gap: 8px;
  align-items: center;
}

.button {
  &.active {
    background: linear-gradient(270deg, $gr-landing);
    color: $default-gray-900;
  }
}

.subtitle {
  color: $default-gray-200;
  margin-top: 4px;
}
