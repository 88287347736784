@use "/src/styles/colors.scss" as *;
@use "/src/styles/mixins.scss" as *;

.wrapper {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.contentBlock {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 533px;
  & h1 {
    font-size: 28px;
    text-align: center;
    margin-top: 40px;
  }
}

.buttonsBlock {
  display: flex;
  margin-top: 40px;
  gap: 20px;
  height: 40px;
  & button {
    width: 160px;
    border-radius: 8px;
  }
  & button:first-child {
    & span {
      @include addGradientToText(to left, $gr-landing);
    }
  }
  & button:last-child {
    box-shadow: 0px 0px 13px 0px $HEXe7e52a99;
    & span {
      color: $default-gray-900;
    }
  }
}
