@use '/src/styles/colors.scss' as *;

.container {
  gap: 8px;
}

.field {
  width: 70px;
  background-color: $bg;
  border-radius: 8px;
  border: 1px solid $default-gray-700;
}

.input {
  padding: 20px 0px;
  font-size: 24px;
  font-weight: 500;
  line-height: 32px;

  &.MuiOutlinedInput-notchedOutline {
    border: none;
  }
}
