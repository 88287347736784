@use '/src/styles/colors.scss' as *;

.chartWrapper {
  display: flex;
  align-items: center;
  padding-bottom: 26px;
  gap: 30px;
  border-bottom: 1px solid $default-gray-600;
}

.chartBlock {
  display: flex;
  align-items: center;
  gap: 16px;
}

.progressBarWrapper {
  margin-top: 26px;
  display: flex;
  gap: 22px;
}

.progressBarContainer {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.progressBarBlock {
  display: flex;
  align-items: center;
}

.circle {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  margin-right: 13px;
}

.progressBar {
  margin-left: 10px;
  width: 124px;
  height: 6px;
  background-color: $bg;
  border-radius: 20px;
  display: flex;
  align-items: center;
}

.progressBarText {
  flex: 1 1 auto;
}

.progress {
  height: 80%;
  border-radius: 20px;
}

.emptyBlock {
  position: relative;
}

.zeroUsersBlock {
  position: absolute;
  top: 36px;
  right: 36px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4px;
}
