@use '/src/styles/colors.scss' as *;

$scrollbar-width: 4px;
$scrollbar-border-radius: 40px;

.field {
  width: 100%;

  & div:global.Mui-focused {
    background-color: $default-gray-700;
  }

  & button:global.MuiIconButton-root {
    color: $default-gray-200;
  }

  & label:global.MuiFormLabel-root {
    font-weight: 300;
    color: $default-gray-200;
  }
}

.input {
  font-weight: 400 !important;
  font-size: 16px;
  line-height: 22px;
  background-color: $bg-color;
  padding: 12px 16px;
  border: 1px solid $default-gray-600;
  transition: all;
  transition-duration: 0.3s;

  & > input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    caret-color: $white;
    -webkit-text-fill-color: $white;
    -webkit-background-clip: text !important;
  }

  &:hover {
    background-color: $bg-color;
  }

  & input::placeholder {
    opacity: 1;
    color: $default-gray-300;
    font-weight: 400 !important;
  }
}

.businesses {
  overflow-y: auto;

  scrollbar-width: thin;
  scrollbar-color: $default-gray-500 transparent;

  &::-webkit-scrollbar {
    width: $scrollbar-width;
    border-radius: $scrollbar-border-radius;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $default-gray-400;
    border-radius: $scrollbar-border-radius;
  }
}

.selectedBusiness {
  background: linear-gradient(120deg, $gr-landing);
}
