.wrapper {
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 28px;
}

.switcherBlock {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}
