$tile_width: 300px;

.wrapper {
  height: 100%;
  width: 1233px;
}

.carousel {
  perspective: 500px;
  perspective-origin: 50% 10%;
  height: 625px;
  width: 100%;
}

.carousel-tile {
  position: absolute;
  left: calc(50% - calc($tile_width / 2));
  cursor: pointer;
}

[data-position='0'] {
  top: 55px;
  transform: translate3d(-75px, 0, 0);
  z-index: 1;
}

[data-position='1'] {
  transform: translate3d(370px, 160px, 100px);
  z-index: 4;
}

[data-position='-1'] {
  transform: translate3d(-208px, 160px, 100px);
  z-index: 4;
}

[data-position='2'] {
  transform: translate3d(545px, 160px, 100px);
  z-index: 3;
}

[data-position='-2'] {
  transform: translate3d(-380px, 160px, 100px);
  z-index: 3;
}
