@use '/src/styles/colors.scss' as *;

.header {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
}

.headerAndSelects {
  min-width: 836px;
  display: flex;
  flex-direction: column;
  gap: 32px;
}

.selectsWithButton {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.dropdowns {
  display: flex;
  align-items: center;
  gap: 12px;
}
