@use '/src/styles/colors.scss' as *;

.timePickerButton {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 12px;

  &:hover {
    background: $default-gray-600;
  }

  &.active {
    background: linear-gradient(270deg, #34d399 0%, #fff961 100%);
    & > p {
      font-weight: 500;
      color: $default-gray-900;
    }
  }
}
