@use '/src/styles/colors.scss' as *;

.titleContainer {
  position: absolute;
  display: flex;
  align-items: center;
  gap: 8px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -100%);
}

.title {
  color: $white;
  font-size: 48px;
  line-height: 56px;
}
