@use '/src/styles/colors.scss' as *;
@use '/src/styles/mixins.scss' as *;

.container {
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.text {
  @include addGradientToText(to left, $gr-landing);
  user-select: none;
}
