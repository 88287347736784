.container {
  width: 460px;
}

.permissionsContainer {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.permissions {
  display: flex;
  flex-direction: column;
  gap: 15px;
}
