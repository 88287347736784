@use '/src/styles/colors.scss' as *;

.wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 24px 24px 1px;
  border-radius: 16px;
  background-color: $bg-color;

  @media (min-width: 1288px) {
    max-width: 600px;
  }
}

.chartCard {
  display: flex;
  justify-content: flex-start;
  gap: 24px;
  padding-bottom: 25px;
  margin-bottom: 25px;
  border-bottom: 1px solid $default-gray-600;
}

.header {
  display: flex;
  justify-content: flex-start;
  margin-bottom: 25px;
}

.chartContainer {
  width: 104px;
}

.emptyBlock {
  position: relative;
}

.zeroUsersBlock {
  position: absolute;
  top: 36px;
  right: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4px;
}
