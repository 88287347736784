@use '/src/styles/colors.scss' as *;

.container {
  width: 100%;
  border-radius: 12px;
  border: 1px solid transparent;
  background: linear-gradient(transparent, transparent) padding-box,
    linear-gradient(45deg, $landing-green2, $landing-green1) border-box;
}

.button {
  border-radius: 12px;
}
