@use "/src/styles/colors.scss" as *;

.blurWrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}

.buttonPosition {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
  text-align: center;
}

.textContainer {
  display: flex;
  gap: 16px;
  align-items: center;
}

.button {
  margin-top: 20px;
  border-radius: 12px;
  & span {
    color: $default-gray-900;
  }
}
