@use '/src/styles/colors.scss' as *;

.container {
  padding: 0px 9px;
  border-radius: 8px;
  background-color: $default-gray-300;
  cursor: pointer;
  user-select: none;
}

.icon {
  font-weight: 400;
  font-size: 1.5em;
}
