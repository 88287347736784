.container {
  width: 100%;
  height: 100%;
}

.tooltipContainer {
  position: absolute;
  pointer-events: none;
}

.markerContainer {
  pointer-events: none;
}

.grid {
  pointer-events: none;
  color: rgb(255, 255, 255, 0.05);
}

.axesDescription {
  font-size: 8px;
  font-weight: 400;
  line-height: 8px;
}
