@use '/src/styles/colors.scss' as *;

.scrollContainer {
  max-height: 360px;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #444444;
  padding-bottom: 16px;
}

.buttonText {
  color: white;


  &.notActive{
    background: linear-gradient(
        291deg,
        $landing-green1 -14.1%,
        $landing-green2 106.43%
      );
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
  }
}

.tagsList {
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
  padding-top: 16px;;
}

.availableTagsContainer {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.descriptionBtn{
  border-color: $landing-green1 !important;
  width: 142px;
  cursor: auto;
  background: var(--gr-for-buttons, linear-gradient(120deg, rgba(243, 244, 246, 0.30) 40.05%, rgba(184, 254, 97, 0.62) 125.4%));


  &.notActive{
    background: var(--Default-gray-700, $default-gray-700);
  }
}
