.graphContainer {
  height: 342px;
  margin-top: -20px;
}

.emptyWrapper {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.emptyContainer {
  max-width: 260px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 4px;
}
