.header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 44px;
}

.businessTypeContainer {
  padding-top: 32px;
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.businessDetailsWithHeader {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.businessDetailsContainer {
  display: flex;
  flex-direction: column;
  gap: 32px;
}

.scheduleContainer {
  display: flex;
  flex-direction: column;
  gap: 40px;
}

.scheduleHeader {
  padding-top: 14px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
}

.memberContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 32px;
}

.submitModalButtons {
  display: flex;
  padding-top: 32px;
  gap: 24px;
}
