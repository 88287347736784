@use '/src/styles/colors.scss' as *;

.chartsContainer {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 24px;
}

.wireframesChart {
  background-color: $bg-color;
  border-radius: 16px;
  width: 100%;

  @media (min-width: 1288px) {
    max-width: 600px;
  }
}
