@use "/src/styles/colors.scss" as *;
@use "/src/styles/mixins.scss" as *;

.wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $default-gray-700;
  padding: 2px 10px;
  border-radius: 10px;
  margin-left: 8px;
}

.text {
  @include addGradientToText(to left, $gr-landing);
}
