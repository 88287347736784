@use '/src/styles/colors.scss' as *;

.wrapper {
  background-color: $bg-color;
  border-radius: 16px;
  padding: 24px;
  flex: 1 1 auto;
}

.title {
  color: $default-gray-100;
  margin-bottom: 4px;
}

.gainedText {
  margin-top: 4px;
  color: $default-gray-300;
}

.gainedValue {
  color: $HEXB8FE61;
}

.demographicsBlock {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 20px;
  margin-top: 27px;
}

.demographicCard {
  padding: 16px 20px;
  border-radius: 8px;
  background-color: $default-gray-700;
  min-width: 178px;
}

.demographicHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.demographicRangeText {
  color: $default-gray-100;
}

.viewGraphWrapper {
  height: 48px;
  margin-top: 28px;
}
