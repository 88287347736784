@use '/src/styles/colors.scss' as *;

.map {
  height: 221px;
  border-radius: 20px;
  overflow: hidden;
}

.marker {
  padding: 2px;
  background-color: white;
}
