@use '/src/styles/colors.scss' as *;

.cell {
  min-width: 120px;
  height: 96px;
  border-bottom: 1px solid $default-gray-700;
}

.likesDislikes {
  display: flex;
  flex-direction: column;

  & > span {
    text-align: left;
  }
}
