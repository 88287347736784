@use '/src/styles/colors.scss' as *;

$border-radius: 8px;

.formControl {
  position: relative;

  border: 1px solid transparent;
  border-radius: $border-radius;
  background: linear-gradient(transparent, transparent) padding-box,
    linear-gradient(45deg, $landing-green2, $landing-green1) border-box;

  & div:global.MuiInputBase-root {
    background-color: $bg-color;
    border-radius: $border-radius;
    line-height: 24px;

    &:hover {
      cursor: pointer;
    }

    & div:global.MuiSelect-select {
      color: transparent;

      & div:global.MuiBox-root {
        padding: 0;
        background: none;

        p {
          color: transparent;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
    }
  }
}

.label {
  padding: 8px 16px;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: $border-radius;
  pointer-events: none;
  position: absolute;
  z-index: 50;
}

.isBlured {
  filter: blur(2px);
  pointer-events: none;
  user-select: none;
}

.labelText {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.chevronDown {
  transform: rotate(-90deg);
}

.gradient {
  background: linear-gradient(120deg, $HEXF3F4F64D 40.05%, $HEXB8FE619E 125.4%);
}
