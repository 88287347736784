@use '/src/styles/colors.scss' as *;

$buttonWidth: 24px;
$buttonHeight: 24px;

.container {
  width: fit-content;
  position: relative;
}

.image {
  border-radius: 8px;
  background: lightgray 50% / cover no-repeat;
}

.iconButton {
  position: absolute;
  top: calc($buttonHeight / -2);
  right: calc($buttonWidth / -2);
  width: $buttonWidth;
  height: $buttonHeight;
  background-color: $HEX2C2C2E;
  border-radius: 100%;
}

.iconButton:hover {
  background-color: $HEX2C2C2E;
}

.clearIcon {
  fill: $HEXEBEBF599;
  font-size: 1rem;
}
