.story {
  position: relative;
  flex-shrink: 0;
  border-radius: 10px;
  user-select: none;
  cursor: pointer;
}

.media {
  border-radius: 16px;
  width: 180px;
  height: 214px;
  object-fit: cover;
}

.dropdown {
  position: absolute;
  top: 55px;
  right: 8px;
}
