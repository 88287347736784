@use '/src/styles/colors.scss' as *;
@use '/src/styles/mixins.scss' as flexbox;

.wrapper {
  position: relative;
  background-color: $bg-color;
  border-radius: 6px;
}

.cell {
  border-bottom: 1px solid $default-gray-700;
}

.cellComment {
  width: 260px;
}

.addWaterfallHeader {
  display: flex;
  flex-direction: column;
}

.addCircleIcon {
  fill: $default-gray-400;
}

.emptyBlock {
  @include flexbox.flexbox-center;
  padding: 167px 0 181px;
}
