.wrapper {
  padding-top: 40px;
  width: 520px;
  display: flex;
  flex-direction: column;
  gap: 40px;
}

.container {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.forgotPassword {
  align-self: start;
}

.submitButton {
  width: 200px;
  align-self: end;
}
