@use '/src/styles/colors.scss' as *;

.wrapper {
  min-width: 389px;
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px 16px;
  border-radius: 8px;
  background: rgba(38, 38, 38, 0.77);
  backdrop-filter: blur(20px);
  margin-left: 8px;
}
