@use '/src/styles/colors.scss' as *;

.checkboxWrapper {
  margin: 0;
  display: flex;
  gap: 8px;

  & span:global.Mui-checked {
    color: white;
  }
}

.checkbox {
  width: 16px;
  height: 16px;

  padding: 0;
  color: transparent;
  background-color: $default-gray-700;
  border: 1px solid $default-gray-500;
  border-radius: 4px;
}
