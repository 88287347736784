@use "/src/styles/colors.scss" as *;

.wrapper {
  & div:global.MuiInputBase-multiline {
    line-height: 20px;
    color: $default-gray-300;
    font-size: 16px;
    font-weight: 400;
    & textarea {
      padding-top: 6px;
    }
  }

  & label:global.MuiFormLabel-root {
    font-weight: 300;
    color: $default-gray-200;
  }
}
