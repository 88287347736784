@use '/src/styles/colors.scss' as *;

.description {
  color: $default-gray-200;
}

.container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: inherit;
}

.fields {
  display: flex;
  flex-direction: column;
  gap: 40px;
  width: inherit;
}

.mainText {
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: inherit;
}
