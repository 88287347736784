@use '/src/styles/colors.scss' as *;
@use '/src/styles/mixins.scss' as flexbox;

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.pencilIconContainer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-basis: 30%;
  flex-shrink: 0;
}

.pencilIcon {
  display: flex;
  align-items: center;
  background: -webkit-linear-gradient(135deg, $gr-landing);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.membersList {
  max-height: 194px;
  padding-right: 6px;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.member {
  border-radius: 12px;
  padding: 16px 16px;
  display: flex;
  align-items: center;
  background: $default-gray-700;
}

.memberFullNameAndTitle {
  display: flex;
  align-items: center;
  flex-basis: 35%;
  flex-shrink: 0;
  padding-right: 12px;
  overflow: hidden;
}

.memberFullName {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding-right: 4px;
}

.organizationContainer {
  padding-left: 12px;
  border-left: 1px solid $default-gray-400;
  flex-basis: 35%;
  flex-shrink: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: $HEXB8FE61;
}

.organization {
  text-align: center;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: $HEXB8FE61;
}

.emptyMembers {
  @include flexbox.flexbox-center;
  padding: 56px 0;
}

.emptyMembersText {
  display: flex;
  flex-direction: column;
  text-align: center;
  width: 317px;
  gap: 10px;
}
