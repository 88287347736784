@use '/src/styles/colors.scss' as *;

$scrollbar-width: 6px;
$scrollbar-border-radius: 20px;

.backdrop {
  & div:global.MuiModal-backdrop {
    background-color: $HEX08080899;
    backdrop-filter: blur(4px);
  }
}

.paper {
  background-color: transparent;
  border-radius: 16px;

  scrollbar-width: thin;
  scrollbar-color: $default-gray-500 transparent;

  &::-webkit-scrollbar {
    width: $scrollbar-width;
    border-radius: $scrollbar-border-radius;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $default-gray-500;
    border-radius: $scrollbar-border-radius;
  }
}

.container {
  position: relative;
  border-radius: 16px;
  background-color: $bg-color;
}

.closeButton {
  width: 30px;
  height: 30px;
  padding: 13px;
  color: $HEXEBEBF599;
  background-color: $HEX2C2C2E;
  &:hover {
    background-color: $HEX2C2C2E;
  }
}
