@use '/src/styles/colors.scss' as *;

.wrapper {
    width: 540px;
    background-color: $bg-color;
    padding: 20px;

    display: flex;
    flex-direction: column;
    align-items: center;

    .map {
        height: 493px;
        width: 493px;
    }
}
