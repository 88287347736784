@use '/src/styles/colors.scss' as *;

.container {
  width: 65px;
  padding: 20px 0px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.mediaWithBadge {
  width: inherit;
  position: relative;
}

.badge {
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  padding: 4px 14px;
  border-radius: 16px;
  background-color: $default-gray-700;
  font-size: 8px;
  z-index: 2;

  & > span {
    color: $HEXB8FE61;
  }
}

.image {
  width: 65px;
  height: 91px;
  object-fit: cover;
  border-radius: 6px;
}

.media {
  z-index: -1;
  border-radius: 6px;
}

.progress {
  height: 6px;
  border-radius: 20px;
  background-color: $bg;

  & span:global.MuiLinearProgress-bar1Determinate {
    background-color: $HEXB8FE61;
  }
}
