@use '/src/styles/mixins.scss' as flexbox;

.button {
  display: flex;
  @include flexbox.flexbox-center;

  & > * {
    cursor: pointer;
  }
}
