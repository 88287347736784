.container {
  max-width: 490px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px;
}
@media only screen and (max-width: 600px) {
    .container {
        transform: scale(0.8);
    }
}


