@use '/src/styles/colors.scss' as *;

.wrapper {
  display: flex;
  align-items: center;
  gap: 4px;
  padding: 4px 2px;
  border-radius: 4px;
  background-color: $bg-color;
}

.button {
  display: flex;
  align-items: center;
  gap: 4px;
  padding: 8px 30px;
  border-radius: 4px;

  &.active {
    color: $default-gray-900;
    background: linear-gradient(270deg, $gr-landing);
  }
}
