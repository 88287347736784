.container {
  width: 100%;
  max-width: 422px;
  display: flex;
  gap: 10px;
  align-items: center;
  padding-right: 24px;
}

.text {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  min-width: 234px;
  width: 100%;
}

.media {
  flex-shrink: 0;
  width: 80px;
  height: 72px;
  object-fit: cover;
  border-radius: 6px;
}

.button {
  font-weight: 600;
}

.buttonChecked {
  font-weight: 600;
}

.checkIcon {
  width: 16px;
  height: 20px;
}
