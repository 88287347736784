@use '/src/styles/colors.scss' as *;
@use '/src/styles/mixins.scss' as flexbox;

.statsTitle {
  color: $default-gray-100;
}

.usersWrapper {
  max-height: 144px;
  height: 100%;
  overflow: auto;
  padding: 7px 0;
  padding-right: 12px;

  margin-right: -15px;
  &::-webkit-scrollbar {
    width: 4px;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 20px;
    background-color: $default-gray-400;
  }
}

.userFlex {
  display: flex;
  justify-content: space-between;
  align-items: center;
  &:not(:first-child) {
    margin-top: 12px;
  }
}

.userBlock {
  display: flex;
  align-items: center;
}

.userTimeText {
  color: $default-gray-200;
}

.emptyBlock {
  @include flexbox.flexbox-center;
  padding: 41px 0 65px;
}
