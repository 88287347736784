@use '/src/styles/colors.scss' as *;

.container {
  width: 97px;
  height: 44px;
  padding: 8px 12px;
  border: 1px solid #8b8b8b;
  border-radius: 8px;
  background: linear-gradient(141deg, #6d6d6d 43%, #82826a 100%);

  &::before {
    content: '';
    position: absolute;
    top: 50%;
    left: -7px;
    transform: translateY(-50%);
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 7px 8px 7px 0;
    border-color: transparent #8b8b8b transparent transparent;
  }
}

.title {
  font-family: Poppins;
  font-size: 8px;
  font-style: normal;
  font-weight: 500;
  line-height: 8px;
}

.statsBlock {
  color: $white;
  display: flex;
  align-items: center;
  gap: 8px;
}

.percentBlock {
  display: flex;
  align-items: baseline;
  gap: 2px;
}

.triangle {
  width: 6px;
  height: 6px;
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-bottom: 6px solid $HEXB8FE61;

  &.negative {
    border-top: 6px solid $profile-red;
    border-bottom: 0;
  }
}

.percent {
  font-size: 9.764px;
  color: $HEXB8FE61;

  &.negative {
    color: $profile-red;
  }
}

.backgroundBorder {
  position: relative;
  bottom: 36px;
  left: -12px;
  z-index: -1;
  height: 44px;
  width: 97px;
  border-radius: 8px;
  background: linear-gradient(42deg, #39b04a 43%, #d4df28 100%);
}
