@use '/src/styles/colors.scss' as *;

$scrollbar-height: 8px;
$scrollbar-border-radius: 20px;

.waterfalls {
  padding-bottom: 4px;
  display: flex;
  gap: 16px;

  overflow-x: scroll;

  scrollbar-width: thin;
  scrollbar-color: $default-gray-500 transparent;

  &::-webkit-scrollbar {
    height: $scrollbar-height;
    border-radius: $scrollbar-border-radius;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $default-gray-500;
    border-radius: $scrollbar-border-radius;
  }
}

.wrapper {
  padding-top: 32px;

  display: flex;
  flex-direction: column;
  gap: 32px;
}

.header {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.hrModal {
  padding: 40px;
  background: $bg-color;

  display: flex;
  flex-direction: column;
  gap: 32px;
}

$row-gap: 24px;

.waterfallsList {
  width: 825px;
  display: flex;
  column-gap: $row-gap;
  row-gap: 32px;
  flex-wrap: wrap;
}

.waterfallsList > * {
  width: 390px;

  @media (min-width: 985px) {
    width: 100%;
    flex: 0 0 calc(50% - $row-gap);
  }
}
