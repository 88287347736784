.wrapper {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 24px;
  flex-wrap: wrap;
}

.toogleBox {
  display: flex;
  align-items: center;
  gap: 8px;
}
