.wrapper {
  width: 520px;
  padding-top: 24px;
  display: flex;
  flex-direction: column;
  gap: 40px;
}

.container {
  display: flex;
  flex-direction: column;
}

.submitButton {
  width: 200px;
  align-self: end;
}

.row {
  display: flex;
  gap: 16px;
}
