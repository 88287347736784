@use '/src/styles/colors.scss' as *;

.container {
  display: flex;
}

.leftContainer {
  padding-right: 40px;
  border-right: 1px;
  border-right-style: solid;
  border-color: $default-gray-600;
  display: flex;
  flex-direction: column;
  gap: 32px;
}

.headerWithReceiptsContainer {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.textDimmed {
  color: $default-gray-200;
}

.textWrapper {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.bothButtonsContainer {
  display: flex;
  flex-direction: column;
  gap: 50px;
}

.buttonWrapper {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.uploadButtonContainer {
  border-radius: 12px;
  background: linear-gradient(
    120deg,
    rgba(243, 244, 246, 0.3) 40.05%,
    rgba(184, 254, 97, 0.62) 125.4%
  );
}

.uploadButtonContent {
  display: flex;
  align-items: center;
  gap: 8px;
}

.receipts {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.receiptExampleContainer {
  padding-left: 40px;
  display: flex;
  flex-direction: column;
  gap: 24px;
}
