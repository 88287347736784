@use '/src/styles/colors.scss' as *;
@use '/src/styles/mixins.scss' as flexbox;

.container {
  @include flexbox.flexbox-center;
}

.wrapper {
  position: relative;
  width: 228px;

  &
    div:global.MuiAutocomplete-root
    .MuiOutlinedInput-root
    .MuiAutocomplete-input {
    padding-right: 27px;
  }

  &
    div:global.MuiInputBase-root.MuiOutlinedInput-root.Mui-focused
    .MuiOutlinedInput-notchedOutline {
    border-color: $HEXb8fe61b2;
    border-width: 1px;
  }
}

.field {
  position: relative;
  width: 228px;
}

.input {
  border-radius: 12px;
  color: $white;
  background-color: $default-gray-700;

  & div:global.MuiInputBase-root.MuiOutlinedInput-root {
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
    padding: 2px 14px;
    border-radius: 12px;
    caret-color: $HEXb8fe61b2;
  }
}

.suggestions {
  margin-top: 12px;
  font-size: 14px;
  background-color: $default-gray-700;
  border-radius: 12px;

  &:hover {
    cursor: pointer;
  }

  .MuiAutocomplete-popper {
    padding: 0;
    border: none;
  }
}

.buttonSend {
  position: absolute;
  right: 13px;
  top: 9px;
  cursor: pointer;
}

.inputComplete {
  width: 228px;
  border-radius: 12px;
  background: linear-gradient(
    166deg,
    rgba(243, 244, 246, 0.3) 52.05%,
    rgba(184, 254, 97, 0.62) 141.4%
  );
  padding: 0;
  border-radius: 12px;

  & input {
    font-size: 14px;
    font-style: normal;
    font-weight: 300;
    padding: 11px 14px;
    border-radius: 12px;
  }

  & div:global.MuiInputBase-root.MuiOutlinedInput-root {
    border-radius: 12px;
  }

  & input:disabled {
    -webkit-text-fill-color: $white;
  }
}
