@use '/src/styles/colors.scss' as *;

.react-calendar button {
  margin: 4px 0;
}

.react-calendar__month-view__weekdays {
  color: $default-gray-400;
  border-top: 1px solid $default-gray-600;
  border-bottom: 1px solid $default-gray-600;
  padding: 5px 0;
  margin-bottom: 16px;
}

.react-calendar__tile {
  padding: 6px 10px;
}

.react-calendar__month-view__days__day--weekend {
  color: $white;
}

.react-calendar__tile--now.react-calendar__tile--active {
  background-color: $bg-color;
  color: $HEXB8FE61;
}

.react-calendar__tile--now.react-calendar__tile--active.react-calendar__tile--range {
  color: $white;
  background: #3b4929;
}

.react-calendar__tile--now.react-calendar__tile--range.react-calendar__tile--active.react-calendar__tile--rangeBothEnds {
  background-color: $bg-color;
  color: $HEXB8FE61;
}

.react-calendar__tile--now {
  background-color: $bg-color;
  color: $HEXB8FE61;
}

.react-calendar__tile--active {
  background: #3b4929;
}

.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
  background: $HEXB8FE61;
  color: #324020;
}

.react-calendar--selectRange .react-calendar__tile--hover {
  background-color: #324020;
  color: $white;
}

.react-calendar__tile--range.react-calendar__tile--active.react-calendar__tile--rangeStart {
  background: $HEXB8FE61;
  color: #324020;
  border-radius: 2px;
}

.react-calendar__tile--range.react-calendar__tile--active.react-calendar__tile--rangeEnd {
  background: $HEXB8FE61;
  color: #324020;
  border-radius: 2px;
}

.react-calendar__navigation__next2-button {
  display: none;
}

.react-calendar__navigation__prev2-button {
  display: none;
}

.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
  background: $bg-color;
  color: $HEXB8FE61;
}

.react-calendar__tile:disabled {
  background: $HEXB8FE61;
  color: #324020;
}

.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
  background: $HEXB8FE61;
  color: #324020;
}

.react-calendar__navigation button {
  min-width: 20px;
}

.react-calendar__month-view__weekdays {
  font-weight: 400;
  font-size: 12px;
}

.react-calendar__month-view__weekdays abbr[title] {
  text-decoration: none;
  text-transform: capitalize;
}

.react-calendar__month-view__days {
  width: 272px;
}

.react-calendar__month-view__weekdays {
  justify-content: space-between;
  width: 272px;
  user-select: none;
}

.react-calendar__navigation {
  height: 22px;
  margin-top: 12px;
  margin: 12px 12px 0 12px;
  align-items: center;
}

.react-calendar {
  width: 296px;
  font-family: Poppins;
}

.react-calendar__month-view__days__day--neighboringMonth {
  color: #585858;
}

.react-calendar__navigation__label__labelText {
  font-size: 15px;
}
