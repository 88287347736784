@use '/src/styles/colors.scss' as *;

.container {
  width: 324px;
  height: 604px;
  padding: 20px 20px 0;
  border-radius: 16px;
  background-color: $bg-color;
}

.wrapper {
  height: 538px;
  margin-top: 16px;
  overflow: auto;
}

.wrapper::-webkit-scrollbar {
  width: 0px;
}

.wrapper::-webkit-scrollbar-thumb {
  background-color: transparent;
}

.wrapper::-webkit-scrollbar-track {
  background-color: transparent;
}
