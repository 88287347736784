@use '/src/styles/colors.scss' as *;
@use '/src/styles/variables.scss' as var;

$basicLength: 239px;

.stats {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  padding: 22.494px 19.683px;
  border-radius: 14px;
  background-color: $bg-color;
  height: $basicLength;

  .box {
    display: flex;
    flex-direction: column;
    gap: 11.247px;
    padding: 9.841px 11.247px;
    background-color: $default-gray-700;
    border-radius: 11.247px;
    align-items: center;
    width: 100%;
  }
}

.blurred {
  filter: blur(4px);
}
