.container {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.companyName {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
