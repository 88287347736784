@use '/src/styles/colors.scss' as *;

.business {
  transition: all 0.3s;
  cursor: pointer;

  &:hover {
    background-color: $default-gray-700;
  }
}
