@use '/src/styles/colors.scss' as *;

.wrapper {
  margin-bottom: 32px;
  border-bottom: 1px solid $default-gray-700;
  min-height: unset;
}

.button {
  color: $default-gray-300;
  text-transform: initial;
  font-size: 12px;
  line-height: 16px;
  font-weight: 400;
  padding: 14px 8px;
  min-height: unset;
  position: relative;
  &:after {
    content: '';
    opacity: 0;
  }

  &:global.Mui-selected {
    color: $HEXB8FE61;
    cursor: auto;
    transition: all 0.3s ease-in-out;

    &:after {
      content: '';
      opacity: 1;
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 2px;
      background: linear-gradient(to left, $gr-landing);
      transition: all 0.3s ease-in-out;
    }
  }
}
