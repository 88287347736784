@use '/src/styles/colors.scss' as *;
@use '/src/styles/mixins.scss' as *;
@use '/src/styles/variables.scss' as var;

.appBar {
  &:global.MuiAppBar-root {
    background-color: $default-gray-700;
    box-shadow: none;
  }
}

.toolBar {
  height: var.$headerHeight + px;
  display: flex;
  justify-content: space-between;
  max-width: var.$container + var.$spacing24 * 2 + px;
  width: 100%;
  padding: 0 var.$spacing24 + px;
  margin: 0 auto;
}

.flex {
  display: flex;
  align-items: center;
  flex-direction: row;
}

.list {
  display: flex;
  align-items: center;
  flex-direction: row;
  align-self: end;
  padding: 0;
}

.listItem {
  padding: 0;
  position: relative;
  &:not(:last-child) {
    margin-right: 24px;
  }
  & a {
    color: $default-gray-100;
    padding: 5px 12px 30px 12px;
    cursor: pointer;
  }
  &:after {
    content: '';
    opacity: 0;
  }
  &.active {
    opacity: 1;
    @include addGradientToText(to left, $gr-landing);
    & a {
      cursor: auto;
    }
    &:after {
      content: '';
      opacity: 1;
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 2px;
      background: linear-gradient(to left, $gr-landing);
      transition: all 0.3s ease-out;
    }
  }
}
