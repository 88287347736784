@use '/src/styles/colors.scss' as *;
@use '/src/styles/variables.scss' as var;

.root {
  display: flex;
  width: 100%;
  max-width: 1440px;
  border-radius: 16px;
  overflow: hidden;

  @media screen and (max-width: var.$lgScreen) {
    flex-direction: column;
  }

  .background {
    background: $bg-color !important;
  }

  .info {
    flex: 1;
    padding: 72px 12px;
    background: linear-gradient(338deg, #121614 -7.31%, #48542a 113.84%);
    display: flex;
    gap: 20px;
    justify-content: center;
    position: relative;

    @media screen and (max-width: var.$lgScreen) {
      padding: 12px 8px;
    }

    @media screen and (max-width: var.$sxScreen) {
      flex-direction: column;
    }

    .logo {
      transform: translate(-50%, -50%);

      @media screen and (max-width: var.$sxScreen) {
        display: none;
      }
    }

    .column {
      display: flex;
      flex-direction: column;
      gap: 20px;
      width: 100%;
      max-width: 239px;

      @media screen and (max-width: var.$sxScreen) {
        max-width: none;
      }
    }
  }
}
