@use '/src/styles/colors.scss' as *;

.container {
  border-radius: 16px;
  position: relative;
}

.overlay {
  border-radius: 16px;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 999;
  width: 100%;
  height: 100%;
  backdrop-filter: blur(7px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 32px;
}

.text {
  user-select: none;
  text-shadow: 0px 0px 16px $manz;
}
