@use '/src/styles/colors.scss' as *;

.container {
  width: 460px;
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.addHolidaySchedule {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 8px;

  background: linear-gradient(
    298deg,
    $landing-green1 -5.98%,
    $landing-green2 113.62%
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
