@use '/src/styles/colors.scss' as *;

.container {
  display: flex;
  align-items: center;
  gap: 8px;
}

.buttonImage {
  padding: 0;
  cursor: auto;
  &.clickable {
    cursor: pointer;
  }
}

.image {
  width: inherit;
  height: inherit;
  border-radius: 50%;
  object-fit: cover;
  color: $default-gray-200;
}
