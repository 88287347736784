@use '/src/styles/colors.scss' as *;

$scrollbar-width: 6px;
$scrollbar-border-radius: 20px;

.container {
  overflow-y: scroll;
  scrollbar-width: thin;
  scrollbar-color: $default-gray-500 transparent;

  &::-webkit-scrollbar {
    width: $scrollbar-width;
    border-radius: $scrollbar-border-radius;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $default-gray-500;
    border-radius: $scrollbar-border-radius;
  }
}
