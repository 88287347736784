@use '/src/styles/colors.scss' as *;

.container {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 999;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #00000020;
  backdrop-filter: blur(4px);
  overflow: hidden;
}
