@use "/src/styles/colors.scss" as *;

.subtitle {
  text-align: center;
  color: $default-gray-200;
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 4px;
}

.title {
  line-height: 115%;
  text-transform: upperCase;
  text-align: center;
}
