@use '/src/styles/colors.scss' as *;
@use '/src/styles/mixins.scss' as flexbox;

.container {
  @include flexbox.flexbox-center;
  width: 45px;
  height: 20px;
  margin-left: 8px;
  padding: 2px 4px;
  border-radius: 4px;
  background: linear-gradient(to left, $gr-landing);
}

.text {
  font-size: 12px;
  color: $bg-color;
}
