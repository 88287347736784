@use '/src/styles/colors.scss' as *;

.wrapper {
  display: inline-flex;
  flex-direction: column;
}

.likes {
  color: $default-gray-200;
}
