@use '/src/styles/colors.scss' as *;

.blockActivity {
  padding: 48px 0;

  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 24px;

  @media (min-width: 1100px) {
    flex-wrap: nowrap;
  }
}

.chartContainer {
  height: 275px;
  display: flex;
  flex-direction: column;
}

.categoriesBlock {
  display: flex;
  justify-content: space-between;
  padding: 0 15px 0 50px;
}
