.container {
  display: flex;
  justify-content: space-between;
  width: 144px;
  margin-bottom: 24px;
}

.setBlock {
  display: flex;
  align-items: center;
  gap: 8px;
}

.circle {
  width: 10px;
  height: 10px;
  border-radius: 100px;
  background: linear-gradient(291deg, #34d399 -14.1%, #fff961 106.43%);
}

.number {
  font-weight: 700;
}
