@use '/src/styles/colors.scss' as *;

.wrapper {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 9px 12px;
  background-color: $default-gray-700;
  border-radius: 8px;
}

.dropdownButton {
  display: flex;
  align-items: center;
  gap: 10px;

  &:active {
    transform: scale(0.95);
  }
}

.binIcon {
  width: 24px;
  height: 24px;
  padding-left: 4px;
}

.deleteModalText {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 6px;
  gap: 12px;
}

.deleteModalButton {
  display: flex;
  flex: 1;
  justify-content: center;
  padding-top: 10px;
  padding-bottom: 10px;

  &:active {
    transform: scale(0.95);
  }
}
