@use '/src/styles/colors.scss' as *;

.subscriptions {
  display: flex;
  flex-direction: column;
  gap: 24px;

  .subscription {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .info {
      display: flex;
      flex-direction: column;
      gap: 8px;
    }
  }
}

.button {
  span {
    color: $HEXB8FE61;
  }

  padding: 0;
  margin: 0;
  min-width: auto;
}
