@mixin addGradientToText($to, $gradient) {
  background: linear-gradient($to, $gradient);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

@mixin flexbox-center {
  display: flex;
  align-items: center;
  justify-content: center;
}
