@use '/src/styles/colors.scss' as *;

.chartBlock {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 36px;

  @media (min-width: 600px) {
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
  }
}

.chart {
  width: fit-content;
  height: fit-content;
}

.cardsWrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 16px;
  max-width: 500px;
}

.cardBody {
  height: fit-content;
  min-width: 160px;
  padding: 12px 20px;
  border-radius: 16px;
  background: $default-gray-700;
}

.emptyBlock {
  position: relative;
}

.zeroUsersBlock {
  position: absolute;
  top: 65px;
  right: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4px;
}
