@use '/src/styles/colors.scss' as *;

$width: 540px;

.wrapper {
  min-width: $width;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.container {
  position: relative;
  background-color: $bg-color;
  max-width: $width;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 40px;
  gap: 40px;
  border-radius: 16px;
}
