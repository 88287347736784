@use '/src/styles/colors.scss' as *;

.headerWithExamples {
  display: flex;
  flex-direction: column;
  gap: 32px;
}

.header {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
}

.heading {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.bottomTextContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.mantrasContainer {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.mantras {
  max-width: 836px;
  display: flex;
  justify-content: center;
  gap: 24px;

  div {
    flex: 1;
  }
}

.questions {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.divider {
  margin: 24px 0px;
  background-color: $default-gray-400;
}
