.container {
  position: relative;
  display: flex;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
}

.icon {
  position: absolute;
  transform-origin: center center;
}
