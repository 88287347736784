@use '/src/styles/colors.scss' as *;

.fields {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: inherit;
}

.bottomContent {
  width: inherit;
  display: flex;
  flex-direction: column;
  gap: 28px;
}

.bottomText {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;
}

.errorText {
  color: $linear-red-unlikes;
}
