@use '/src/styles/colors.scss' as *;

$scrollbar-height: 6px;
$scrollbar-border-radius: 20px;

.wrapper {
  width: 100%;
  padding: 20px 16px 28px 16px;
  border-radius: 10px;
  background: $bg-color;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.scrollableContainer {
  overflow-x: scroll;

  scrollbar-width: thin;
  scrollbar-color: $default-gray-500 transparent;

  &::-webkit-scrollbar {
    height: $scrollbar-height;
    border-radius: $scrollbar-border-radius;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $default-gray-500;
    border-radius: $scrollbar-border-radius;
  }
}
