@use '/src/styles/colors.scss' as *;

.hidden {
  margin-top: 15px;
  background-color: transparent;
  min-width: 150px;
}

.paper {
  margin-top: 15px;
  border-radius: 20px;
  border: 1px solid $default-gray-600;
  background-color: $default-gray-700;
  padding: 16px;
  min-width: 150px;
}
