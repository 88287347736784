.container {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.oneDayRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.pickersWithCheckboxRow {
  display: flex;
  align-items: center;
  gap: 20px;
}

.pickersRow {
  display: flex;
  gap: 16px;
}

.errorContainer {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.errorMessage {
  color: red;
}
