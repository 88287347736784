@use '/src/styles/variables.scss' as var;

.imageBox {
  object-fit: none;
  border-radius: 14px;
  width: 100%;
  height: 239px;
}

.blurred {
  filter: blur(5px);
}
