.wrapper {
  padding-top: 40px;
  width: 520px;
  display: flex;
  flex-direction: column;
  gap: 40px;
}

.containerEmail {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.containerRecovery {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.forgotPassword {
  align-self: start;
}
