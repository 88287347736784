@use '/src/styles/colors.scss' as *;

.chartBlock {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  gap: 30px;
}

.barChartContainer {
  height: 285px;
  width: 100%;

  @media (min-width: 703px) {
    width: calc(100% - 176px - 30px);
  }
}

.expendituresWrapper {
  background-color: $default-gray-700;
  border-radius: 6px;
  padding: 10px 16px;
  width: 100%;

  @media (min-width: 703px) {
    max-width: 176px;
  }

  & span {
    color: $default-gray-100;
  }
}

.expendituresByCategoriesWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 11px;
  gap: 20px;
}
