@use '/src/styles/colors.scss' as *;
@use '/src/styles/variables.scss' as var;

.stats {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  padding: 22.494px 19.683px;
  border-radius: 14px;
  background-color: $bg-color;
  height: 239px;
}
