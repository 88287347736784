@use '/src/styles/colors.scss' as *;
@use '/src/styles/gradients.scss' as *;

.cropArea {
  border: none !important;
}

.avatar {
  width: 350px;
  height: 350px;
  border-radius: 62px;
}

.avatarButton {
  display: inline-flex;
  cursor: pointer;
  padding: 12px;
  border-radius: 10px;
  background-color: $default-gray-700;
}

.buttonsBlock {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.sliderRoot {
  height: 10px;
  color: $default-gray-600;

  & span:global.Mui-focusVisible {
    box-shadow: none;
  }

  & span:global.Mui-active {
    box-shadow: none;
  }
}

.rail {
  opacity: 1;
}

.thumb {
  width: 20px;
  height: 20px;

  background: linear-gradient($default-gray-700, $default-gray-700) padding-box,
    $default border-box;
  border-radius: 50em;
  border: 1px solid transparent;

  &:hover {
    box-shadow: none;
  }
}
