@use '/src/styles/colors.scss' as *;

.businessInfoWrapper {
  border-radius: 8px;
  border: 1px solid transparent;
  background: linear-gradient(transparent, transparent) padding-box,
    linear-gradient(45deg, $landing-green2, $landing-green1) border-box;
}

.businessInfoContainer {
  border-radius: 8px;
  background: $bg-color;
}

.businessInfo {
  padding: 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 8px;
  background: linear-gradient(
    120deg,
    rgba(243, 244, 246, 0.3) 40.05%,
    rgba(184, 254, 97, 0.62) 125.4%
  );
}

.leftSection {
  display: flex;
  align-items: center;
  gap: 24px;
}

.nameAndLocation {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.cancelButton {
  padding: 12px 24px;
}
