@use '/src/styles/colors.scss' as *;

.imageWrapper {
  margin: 24px 0;
  background-image: url('/assets/images/referBusinessBackground.png');
  background-size: 100% 100%;
  background-repeat: no-repeat;
  padding: 24px;
  padding-left: 14px;
  border-radius: 16px;
}

.QRcodeAndTextBlock {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 20px 0 24px 0;
}

.textBlock {
  text-transform: uppercase;
  max-width: 300px;
}

.highlightedText {
  color: $HEXB8FE61;
}

.QRcode {
  width: 142px;
  height: 142px;
}

.footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 24px;
}

.linkBlock {
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
  & span {
    color: $HEXB8FE61;
  }
}

.submitBtn {
  width: 200px;
  border-radius: 6px;
}

.whiteComma {
  display: inline-block;
  color: $white;
}
