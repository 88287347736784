@use '/src/styles/colors.scss' as *;

.container {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 8px;
}

.button {
  &.active {
    background: linear-gradient(270deg, $gr-landing);
    color: $default-gray-900;
  }
}
