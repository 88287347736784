@use '/src/styles/colors.scss' as *;

.fieldGroup{
  position: relative;
}

.label {
  font-weight: 300;
  font-size: 14px;
  color: $default-gray-100;
}

.input {
  width: 100%;
  height: 100%;
  font-weight: 300;
  border-radius: 12px;
  font-size: 14px;
  background-color: $default-gray-700;
  padding: 10px 13px;

  & > input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    caret-color: $white;
    -webkit-text-fill-color: $white;
    -webkit-background-clip: text !important;
  }

  & div:global.Mui-focused {
    background-color: $default-gray-700;
  }

  & button:global.MuiIconButton-root {
    color: $default-gray-200;
  }
}

.result{ 
  background-color: $default-gray-700;
  border-radius: 12px;
  width: 100%;
  max-height: 200px;
  position: absolute;
  left: 0;
  top: 100px;
  z-index: 100;
  padding: 10px 13px;
  overflow-y: auto;;
  display: none;
}

.visible{
  display: block;;
  min-height: 80px;
}

.resultItem {
  display: block;;
  width: 100%;
  text-align: left;
  margin-bottom: 2px;;
}

.loaderWrapper{
  text-align: center;
}

.loader{
  width: 60px;
}