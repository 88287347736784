@use '/src/styles/colors.scss' as *;

.blockActivity {
  padding: 48px 0;

  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  gap: 24px;

  @media (min-width: 1100px) {
    flex-wrap: nowrap;
  }
}

.chartContainer {
  width: 220px;
  height: 220px;
}

.emptyContainer {
  position: relative;
}

.zeroActivity {
  position: absolute;
  top: 86px;
  left: 65px;
  text-align: center;
}
