@use '/src/styles/colors.scss' as *;

.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.gradientBorderContainer {
  margin-bottom: -1px;
  padding-right: 1px;
  padding-bottom: 1px;
  border-radius: 0.5rem /* 8px */;

  background: linear-gradient(
    0deg,
    #39b04a 2%,
    #5aba43 15.59%,
    #9dce34 46.64%,
    #cedd2a 71.87%,
    #ece623 89.34%,
    #f7e921 100.98%
  );
}

.container {
  display: flex;
  align-items: center;
  gap: 0.5rem /* 8px */;
  padding-top: 0.5rem /* 8px */;
  padding-bottom: 0.5rem /* 8px */;
  padding-left: 0.75rem /* 12px */;
  padding-right: 0.75rem /* 12px */;
  border-radius: 0.5rem /* 8px */;
  border: 1px solid rgba(255, 255, 255, 0.2);
  background-color: $default-gray-500;
  backdrop-filter: blur(20px);
}

.percentageContainer {
  display: flex;
  align-items: center;
  gap: 2px;
}

.percentage {
  font-size: 10px;
  font-weight: 500;
  line-height: 14px;
  letter-spacing: -0.2px;
}
