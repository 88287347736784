.iconWrapper {
  width: 36px;
  height: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  position: absolute;
  border-radius: 16px;
  background: rgba(149, 149, 149, 0.3);
  backdrop-filter: blur(2px);

  &:active {
    transform: scale(0.95);
  }

  &:hover {
    cursor: pointer;
  }
}
