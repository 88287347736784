@use '/src/styles/colors.scss' as *;

.chartWrapper {
  display: flex;
  background-color: $bg-color;
  border-radius: 16px;
}

.pieChartWrapper {
  display: flex;
  align-items: center;
  gap: 16px;
  background-color: $bg-color;
  padding: 40px 13px;
  border-radius: 16px;
  flex: 1 0 auto;
}

.circle {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  display: inline-block;
  margin-right: 8px;
}
