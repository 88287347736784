.wrapper {
  margin: 0 auto;
  padding-top: 56px;
  display: flex;
  flex-direction: column;
  gap: 80px;
}

.buttonsContainer {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
