.wrapper {
  height: 222px;
}

.media {
  border-radius: 10px;
  width: 160px;
  height: 222px;
}

.video {
  object-fit: cover;
  border-radius: 10px;
}
