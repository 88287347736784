@use '/src/styles/colors.scss' as *;

.container {
  width: 389px;
  padding: 20px 28px;
  border-radius: 16px;
  background-color: $bg-color;
}

.speedometer {
  width: 333px;
  height: 120px;
  margin: 24px 0;
  overflow: clip;
  padding: 0px 45px;
}

.textBlock {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 14px;
}

.description {
  margin-top: 4px;
  font-weight: 400;
  color: $default-gray-200;
}
