@use '/src/styles/colors.scss' as *;

$border-radius: 6px;

.wrapper {
    display: flex;
    flex-direction: row;
    width: '100%';
    justify-content: space-between;
}

.accordionContent {
  padding: 16px 32px;
  border-radius: 8px;
  border: 1px solid $default-gray-400;

  justify-content: space-between;
  align-items: center;
}

.button {
  border-radius: $border-radius;
  display: flex;
  gap: 16px;
}

.buttonWrapper {
    width: calc(50% - 12px);
  border: 1px solid transparent;
  border-radius: $border-radius;
  background: linear-gradient(transparent, transparent) padding-box,
    linear-gradient(45deg, $landing-green2, $landing-green1) border-box;
}

.inactiveButtonContent {
  color: $bg-color;
}

.gradient {
  background: linear-gradient(
    291deg,
    $landing-green1 -14.1%,
    $landing-green2 106.43%
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
