.container {
  display: flex;
  flex-direction: column;
  gap: 48px;
}

.items {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 28px;
}
