@font-face {
  font-family: 'icomoon';
  src:  url('fonts/icomoon.eot?ut6z8h');
  src:  url('fonts/icomoon.eot?ut6z8h#iefix') format('embedded-opentype'),
    url('fonts/icomoon.ttf?ut6z8h') format('truetype'),
    url('fonts/icomoon.woff?ut6z8h') format('woff'),
    url('fonts/icomoon.svg?ut6z8h#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-arrow-down1:before {
  content: "\e900";
}
.icon-arrow-up1:before {
  content: "\e901";
}
.icon-trash:before {
  content: "\e902";
}
.icon-chevron-back:before {
  content: "\e903";
}
.icon-show:before {
  content: "\e904";
}
.icon-clock:before {
  content: "\e905";
}
.icon-edit-2:before {
  content: "\e906";
}
.icon-plus:before {
  content: "\e907";
}
.icon-home-simple-door:before {
  content: "\e908";
}
.icon-neighbourhood:before {
  content: "\e909";
}
.icon-x-mark:before {
  content: "\e90a";
}
.icon-tick:before {
  content: "\e90b";
}
.icon-qr-code:before {
  content: "\e90c";
}
.icon-lock:before {
  content: "\e90d";
}
.icon-gif:before {
  content: "\e90e";
}
.icon-gallery:before {
  content: "\e90f";
}
.icon-bin:before {
  content: "\e910";
}
.icon-calendar:before {
  content: "\e911";
}
.icon-arrow-left:before {
  content: "\e912";
}
.icon-arrow-down:before {
  content: "\e913";
}
.icon-add-image:before {
  content: "\e914";
}
.icon-circle-with-plus:before {
  content: "\e915";
}
.icon-link-mark:before {
  content: "\e916";
}
.icon-arrow-top:before {
  content: "\e917";
}
.icon-emoji:before {
  content: "\e918";
}
.icon-hide:before {
  content: "\e919";
}
.icon-log-out:before {
  content: "\e91a";
}
.icon-table:before {
  content: "\e91b";
}
.icon-charts:before {
  content: "\e91c";
}
.icon-camera1:before {
  content: "\e91d";
}
.icon-info:before {
  content: "\e91e";
}
.icon-truck:before {
  content: "\e91f";
}
.icon-arrow-right:before {
  content: "\e920";
}
.icon-nlnm:before {
  content: "\e921";
}
.icon-close-circle:before {
  content: "\e922";
}
.icon-tick-circle:before {
  content: "\e923";
}
.icon-exclamation-circle:before {
  content: "\e924";
}
.icon-close-circle1:before {
  content: "\e925";
}
.icon-opposite-arrows:before {
  content: "\e926";
}
.icon-folder:before {
  content: "\e927";
}
.icon-message-text:before {
  content: "\e928";
}
.icon-like:before {
  content: "\e929";
}
.icon-dislike:before {
  content: "\e92a";
}
.icon-send-2:before {
  content: "\e92b";
}
.icon-folder-add:before {
  content: "\e92c";
}
.icon-double-right-arrows:before {
  content: "\e92d";
}
.icon-arrow-circle-left:before {
  content: "\e92e";
}
.icon-arrow-circle-right:before {
  content: "\e92f";
}
.icon-check:before {
  content: "\e930";
}
.icon-arrow-right1:before {
  content: "\e931";
}
.icon-arrow-left1:before {
  content: "\e932";
}
.icon-camera:before {
  content: "\e933";
}
