@use '/src/styles/colors.scss' as *;

.gradientText {
  background: var(--gr-landing, linear-gradient(291deg, $gr-landing));
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.noGradientText {
  color: $white;
  -webkit-text-fill-color: $white;
}
