@use '/src/styles/colors.scss' as *;

.avatarImage {
  display: inline-flex;
  cursor: pointer;
  border-radius: 10px;
  height: 56px;
  width: 56px;
  background-size: cover;
}

.popoverPaper {
  margin-top: 5px;
  border-radius: 8px;
  background-color: $shark;
}

.avatarPopover {
  width: 200px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 9px 12px;
  border-radius: 8px;
  background-color: $shark;

  & > div:hover {
    cursor: pointer;
  }
}
