@use '/src/styles/colors.scss' as *;

.container {
  display: inline-flex;

  background-color: $landing-green1;
  background-size: 100%;
  -webkit-background-clip: text;
  -moz-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-text-fill-color: transparent;
}
