@use '/src/styles/colors.scss' as *;

.container {
  width: 284px;
  padding: 16px;
  margin-bottom: 16px;
  border-radius: 8px;
  background-color: $default-gray-700;
  cursor: pointer;
}

.active {
  border: 1px solid $HEXB8FE61;
}

.topBlock {
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
}

.chartContainer {
  width: 77px;
  height: 20px;
}

.numbers {
  display: flex;
  align-items: center;
}
