@use '/src/styles/colors.scss' as *;

.checkbox {
  display: flex;
  align-items: center;
  gap: 16px;

  &:hover {
    cursor: pointer;
  }
}

.schedulePopover {
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 12px;
  border-radius: 20px;
  border: 1px solid $default-gray-600;
  background-color: $default-gray-700;
}
