@use '/src/styles/colors.scss' as *;
@use '/src/styles/variables.scss' as var;

.root {
  width: 100%;
  max-width: 569px;
  display: flex;
  flex-direction: column;

  gap: 20px;
  background-color: $bg-color;

  @media screen and (max-width: var.$sxScreen) {
    gap: 12px;
  }

  .row {
    display: flex;
    gap: 20px;

    @media screen and (max-width: var.$sxScreen) {
      flex-direction: column;
      gap: 12px;
    }
  }

  .calculation {
    display: flex;
    flex-direction: column;
    gap: 12px;

    .item {
      display: flex;
      justify-content: space-between;
    }
  }

  button {
    background: $profile-blue;
    background-color: $profile-blue !important;
    transition: opacity 0.3s;

    &:hover {
      opacity: 0.8;
      background: $profile-blue;
    }
  }
}
