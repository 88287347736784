@use '/src/styles/colors.scss' as *;

.wrapper {
  margin-left: 25px;
  min-width: 120px;
  padding: 8px 10px;
  border-radius: 14px;
  &.open {
    background-color: $bg;
  }
}

.selectContainer {
  padding-right: 10px;
}

.select {
  display: flex;
  align-items: center;
  gap: 10px;
}

.menu {
  border-radius: 20px;
  background-color: $default-gray-700;
  padding: 16px;
  border: 1px solid $default-gray-600;
  margin-top: 15px;
  & li {
    padding-right: 20px;
    gap: 12px;
    &:hover {
      background-color: transparent;
    }
    &:global.Mui-selected {
      background-color: transparent;
      &:hover:not(:first-child) {
        background-color: transparent;
        cursor: initial;
      }
    }
  }
}

.menuItem {
  padding: 0;
  margin-bottom: 12px;
  & span {
    color: $default-gray-100;
  }
}

.subHeader {
  line-height: 0;
  background-color: transparent;
  padding: 0;
  margin-bottom: 12px;
  & span {
    color: $default-gray-200;
  }
}

.logOut {
  border-top: 1px solid $default-gray-500;
  padding: 0;
  padding-top: 12px;
  margin-bottom: 0;
}

.arrow {
  position: absolute;
  top: Calc(50% - 12px);
  right: 0;
  color: $default-gray-300;
  pointer-events: none;
}

.title {
  display: flex;
  flex-direction: column;
}
