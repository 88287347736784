@use '/src/styles/colors.scss' as *;
@use '/src/styles/variables.scss' as var;

.root {
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: clamp(290px, 100%, 334px);

  .promotedHeader {
    border-radius: 8px 8px 0px 0px;
    background: linear-gradient(91deg, #733aed 1.77%, #422187 100%);
  }

  .product {
    display: flex;
    flex-direction: column;
    align-items: center;
    background: $bg-color;
    padding: 24px 24px 48px;
    border-radius: 8px;
    flex: 1;
    gap: 16px;

    @media screen and (max-width: var.$sxScreen) {
      padding: 24px 0;
    }

    .title {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 178px;
      height: 46px;
      border-radius: 24px;
      border: 1px solid $default-gray-500;
      pointer-events: none;
    }

    .price {
      display: flex;
      flex-direction: column;
      width: 190px;
    }

    ul {
      display: flex;
      flex-direction: column;
      gap: 8px;
      width: 245px;
      list-style-position: inside;
      padding-left: 20px;

      @media screen and (max-width: var.$sxScreen) {
        padding: 0;
      }

      li {
        list-style: disc;
        font-size: x-small;
        color: $default-gray-100;
      }
    }
  }

  .active {
    border: none;
    background: $profile-blue;
    transition: all 0.3s;

    &:hover {
      background: $profile-blue;
      opacity: 0.7;
    }
  }
}
