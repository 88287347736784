@use '/src/styles/colors.scss' as *;

.label {
  color: $default-gray-200;
}

.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px;
  max-width: 460px;
}
