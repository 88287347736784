.wrapper {
  margin-top: 56px;
  display: flex;
  flex-direction: column;
  gap: 40px;
}

.sections {
  display: flex;
  gap: 24px;

  @media (max-width: 1272px) {
    flex-wrap: wrap;
  }
}
