@use '/src/styles/colors.scss' as *;

.label {
  font-weight: 300;
  font-size: 14px;
  color: $default-gray-100;
}

.input {
  width: 100%;
  height: 100%;
  font-weight: 300;
  border-radius: 12px;
  font-size: 14px;
  background-color: $default-gray-700;
  padding: 10px 13px;

  & > input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    caret-color: $white;
    -webkit-text-fill-color: $white;
    -webkit-background-clip: text !important;
  }

  & div:global.Mui-focused {
    background-color: $default-gray-700;
  }

  & button:global.MuiIconButton-root {
    color: $default-gray-200;
  }
}
