@use '/src/styles/colors.scss' as *;

.anchorContainer {
  width: 126px;
  border-radius: 12px;
  padding-left: 16px;
  padding-right: 12px;
  padding-top: 8px;
  padding-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: $default-gray-700;
  cursor: pointer;
}

.anchor {
  display: flex;
  flex-direction: column;
  gap: 2px;
}

.popover {
  border-radius: 12px;
  padding: 6px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  overflow: hidden;
  background-color: $default-gray-700;
}
