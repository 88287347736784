@use '/src/styles/colors.scss' as *;

.waterfall {
  position: relative;
  height: 236px;
  border-radius: 20px;

  cursor: pointer;
}

.iconsBlock {
  position: absolute;
  top: 20px;
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 0 16px;
}

.iconWrapper {
  width: 36px;
  height: 37px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  border-radius: 16px;
  background: rgba(149, 149, 149, 0.3);
  backdrop-filter: blur(2px);
  cursor: pointer;
}

.dropdown {
  position: absolute;
  top: 65px;
  right: 15px;
}

.media {
  width: inherit;
  height: inherit;
  border-radius: 16px;
  object-fit: cover;
  border: 1px solid $HEXB8FE61;
}
