@use '/src/styles/colors.scss' as *;

.wrapper {
  border-radius: 10px;
  border: 1px solid transparent;
  background: linear-gradient(transparent, transparent) padding-box,
    linear-gradient(135deg, $landing-green2, $landing-green1) border-box;
}

.container {
  height: 100%;
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 12px;
  border-radius: 10px;
  background-color: $default-gray-700;
}

.mantraText {
  font-family: 'Pragati Narrow';
  font-size: 22px;
  font-style: normal;
  font-weight: normal;
  line-height: 115%;
}
