@use "/src/styles/colors.scss" as *;

.wrapper {
  margin-top: 16px;
  padding: 40px;
}

.toolbar {
  padding: 12px;
}

.spacer {
  flex: 0;
}

.actionButton {
  margin-left: auto;
}

.displayedRows {
  font-size: 12px;
  line-height: 16px;
}

.menu {
  border-radius: 10px;
  background-color: $default-gray-700;
  border: 1px solid $default-gray-600;
  & li {
    justify-content: center;
    &:hover {
      background-color: transparent;
    }
  }
  & li:global.MuiTablePagination-menuItem {
    &[aria-selected="true"] {
      background-color: $default-gray-600;
      &:hover {
        cursor: initial;
      }
    }
  }
}

.select {
  margin: 0 18px;
  border-radius: 6px;
  background-color: $default-gray-700;
  width: 68px;
}

.input:global.MuiTablePagination-select {
  font-size: 12px;
  line-height: 16px;
  padding: 8px 10px;
  text-align: left;
  text-align-last: left;
}

.arrow {
  width: 16px;
  height: 16px;
  right: 10px;
}
