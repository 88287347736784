.container {
  width: 100%;
  padding: 16px;
  border-radius: 10px;
  background: linear-gradient(
    291deg,
    rgba(52, 211, 153, 0.12) -14.1%,
    rgba(255, 249, 97, 0.12) 106.43%
  );
  display: flex;
  justify-content: space-between;
  align-items: center;
}
