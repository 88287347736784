@use '/src/styles/colors.scss' as *;

$scrollbar-width: 6px;
$scrollbar-border-radius: 20px;

.wrapper {
  width: 100%;
  height: 100%;
  border: 1px double transparent;
  border-radius: 10px;
  background-image: linear-gradient($default-gray-700, $default-gray-700),
    linear-gradient(225deg, $gr-landing);
  background-origin: border-box;
  background-clip: content-box, border-box;
}

.container {
  width: inherit;
  position: relative;
  padding: 12px 16px;
}

.selectContainer {
  position: relative;
  display: flex;
  align-items: center;
}

.select {
  font-family: 'Poppins';
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
}

.dropdown {
  background: $default-gray-700;

  scrollbar-width: thin;
  scrollbar-color: $default-gray-500 transparent;

  &::-webkit-scrollbar {
    width: $scrollbar-width;
    border-radius: $scrollbar-border-radius;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $default-gray-500;
    border-radius: $scrollbar-border-radius;
  }
}

.textarea {
  resize: none;
  width: inherit;
  border: none;
  outline: none;
  background-color: transparent;
  color: $default-gray-100;
  font-family: Poppins;
  font-size: 16px;
  line-height: 20px;

  &:read-only {
    cursor: default;
  }
}

.textarea::placeholder {
  font-family: Poppins;
  color: $default-gray-300;
}

.characters {
  padding-bottom: 12px;
  padding-right: 12px;
  position: absolute;
  right: 0;
  bottom: 0;
}

.gradient {
  background: linear-gradient(0deg, $HEXFAFD74 0%, $HEXBCFF83 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
