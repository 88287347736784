@use '/src/styles/colors.scss' as *;

.buttonsWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap: 16px;
}

.buttonsContainer {
  display: flex;
  gap: 16px;
}

.oneButtonWrapper {
  border-radius: 12px;
  border: 1px double transparent;

  background-image: linear-gradient($bg-color, $bg-color),
    linear-gradient(270deg, $gr-landing);
  background-origin: border-box;
  background-clip: content-box, border-box;
}

.oneButton {
  border-radius: 12px;
  border: 1px double transparent;
  background: linear-gradient(270deg, $gr-landing);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
