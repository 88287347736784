.container {
  max-width: 836px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
}

.dropdowns {
  max-width: 836px;
  display: flex;
  justify-content: center;
  gap: 24px 12px;
  flex-wrap: wrap;
}
