@use '/src/styles/colors.scss' as *;

.wrapper {
  position: relative;
  padding: 40px;
  background-color: $bg-color;
  min-width: 600px;
}

.header {
  display: flex;
  flex-direction: column;
  text-align: center;
  gap: 8px;
  margin-bottom: 24px;
}

.headerText {
  text-shadow: 0px 0px 12px #dbf069;
}

.closeButton {
  position: absolute;
  right: 40px;
  width: 30px;
  height: 30px;
  padding: 13px;
  cursor: pointer;
  color: $HEXEBEBF599;
  background-color: $HEX2C2C2E;
  &:hover {
    background-color: $HEX2C2C2E;
  }
}

.input {
  border-radius: 12px;
  padding: 15px 13px 15px 14px;
  background-color: $default-gray-700;

  & div:global.MuiInputBase-root.MuiOutlinedInput-root {
    border-radius: 12px;
  }

  & textarea:global.MuiInputBase-inputMultiline {
    color: $default-gray-100;
    font-size: 14px;
    font-style: normal;
    font-weight: 300;
    line-height: 20px; /* 142.857% */
  }
}

.formBlock {
  display: flex;
  flex-direction: column;
  gap: 24px;
}
