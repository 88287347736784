@use '/src/styles/colors.scss' as *;

.container {
  display: flex;
  align-self: flex-start;
  align-items: center;
  gap: 8px;
}

.gradient {
  background: linear-gradient(90deg, $HEXFAFD74 0%, $HEXBCFF83 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
