.lowerRomanList {
  list-style-type: lower-roman;
  list-style-position: inside;
}

.lowerLatinParenthesisList {
  display: flex;
  flex-direction: column;
  gap: 24px;

  counter-reset: list;
  list-style-type: lower-latin;
  list-style-position: inside;

  & > li {
    text-indent: 24px;
    list-style: none;

    &:before {
      content: counter(list, lower-alpha) ') ';
      counter-increment: list;
    }
  }
}

.dashedList {
  list-style-type: none;

  & > li {
    text-indent: 24px;

    &:before {
      content: '- ';
    }
  }
}

.bulletList {
  display: flex;
  flex-direction: column;
  gap: 24px;

  list-style-type: none;

  & > li {
    padding-left: 24px;
    text-indent: 24px;

    &:before {
      content: '• ';
    }
  }
}

.weirdTypography {
  font-size: 18px;
  font-style: italic;
  font-weight: 500;
  line-height: 24.5px;
}
