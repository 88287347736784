@use "/src/styles/colors.scss" as *;

.text {
  & li:first-child {
    margin-right: 14px;
  }
  & li:last-child {
    margin-left: 14px;
  }
  & button {
    font-size: 14px;
    line-height: 20px;
    font-weight: 500;
    color: $default-gray-200;
    margin: 0 6px;
    &[aria-current="true"] {
      background: linear-gradient(to left, $gr-landing);
      color: $default-gray-900;
      &:hover {
        cursor: auto;
        background: linear-gradient(to left, $gr-landing);
      }
    }
  }
}
