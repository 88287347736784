@use '/src/styles/colors.scss' as *;

$inactiveDotGradient: linear-gradient(125deg, $gr-for-buttons);

.stepper {
  z-index: 0;
  position: static;
  background: transparent;
  padding: 0;

  & div:global.MuiMobileStepper-dots {
    display: flex;
    gap: 5px;
  }

  & div:global.MuiMobileStepper-dot {
    width: 20px;
    height: 6px;
    border-radius: 20px;
    background: $inactiveDotGradient;
  }

  & div:global.MuiMobileStepper-dotActive {
    background-color: $HEXB8FE61;
  }
}
