@use '/src/styles/mixins.scss' as flexbox;

.wrapper {
  @include flexbox.flexbox-center;
  padding: 173px 0;
}

.container {
  display: flex;
  flex-direction: column;
  text-align: center;
}
