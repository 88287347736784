@use '/src/styles/colors.scss' as *;

.container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.leftSection {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.emailVerified {
  display: flex;
  align-items: center;
  gap: 8px;
}

.textButton {
  span {
    color: $HEXB8FE61;
  }

  padding: 0;
  margin: 0;
  min-width: auto;
}
