@use '/src/styles/colors.scss' as *;

.demographicsBlock {
  margin-top: 24px;
  display: flex;
  gap: 24px;
}

.pieChartWrapper {
  display: flex;
  align-items: center;
  gap: 16px;
  background-color: $bg-color;
  padding: 40px 13px;
  border-radius: 16px;
  flex: 1 0 auto;
}

.circle {
  width: 8px;
  height: 8px;
  border-radius: 50px;
}

.emptyChart {
  position: relative;
}

.zeroNumber {
  position: absolute;
  left: 96px;
  top: 96px;
  color: $HEXB8FE61;
}
