@use '/src/styles/colors.scss' as *;

.input {
  width: 100%;
  background-color: rgba(231, 231, 231, 0.2);
  backdrop-filter: blur(5px);
  padding: 0;
  border-radius: 12px;

  & input {
    font-size: 14px;
    font-style: normal;
    font-weight: 300;
    padding: 8px 12px;
    border-radius: 12px;
  }

  & div:global.MuiInputBase-root.MuiOutlinedInput-root {
    border-radius: 12px;
    caret-color: $HEXb8fe61b2;
  }

  &
    div:global.MuiInputBase-root.MuiOutlinedInput-root.Mui-focused
    .MuiOutlinedInput-notchedOutline {
    border-color: $HEXb8fe61b2;
    border-width: 1px;
  }
}

.inputComplete {
  width: 100%;
  border-radius: 12px;
  background: linear-gradient(
    166deg,
    rgba(243, 244, 246, 0.3) 52.05%,
    rgba(184, 254, 97, 0.62) 141.4%
  );
  padding: 0;
  border-radius: 12px;

  & input {
    font-size: 14px;
    font-style: normal;
    font-weight: 300;
    padding: 8px 12px;
    border-radius: 12px;
  }

  & div:global.MuiInputBase-root.MuiOutlinedInput-root {
    border-radius: 12px;
  }

  & input:disabled {
    -webkit-text-fill-color: $white;
    border-color: transparent;
  }

  &
    div:global.MuiInputBase-root-MuiOutlinedInput-root.Mui-disabled
    .MuiOutlinedInput-notchedOutline {
    border-color: transparent;
  }
}
