@use '/src/styles/colors.scss' as *;
@use '/src/styles/mixins.scss' as *;

.wrapper {
  width: 100%;
  background: linear-gradient(to left, $gr-landing);
  padding: 28px 32px;
  border-radius: 16px;
  position: relative;
  overflow: hidden;
  flex: 0 0 auto;
  &::after {
    content: '';
    position: absolute;
    top: 0;
    right: 30%;
    background-image: url('/assets/icons/backgroundLogo.svg');
    height: 51px;
    width: 51px;
    background-size: 50px 50px;
    background-repeat: no-repeat;
    transform: rotate(-11.8deg);
  }
  &::before {
    content: '';
    position: absolute;
    top: 30px;
    right: 0;
    background-image: url('/assets/icons/backgroundLogo.svg');
    height: 131px;
    width: 131px;
    background-size: 130px 130px;
    background-repeat: no-repeat;
  }
}

.title {
  color: $default-gray-900;
  font-size: 20px;
  font-weight: 400;
  line-height: 20px;
  text-align: center;

  @media (min-width: 500px) {
    text-align: start;
  }
}

.buttonsBlock {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
  gap: 10px;

  @media (min-width: 500px) {
    flex-direction: row;
  }
}

.buttonBoost {
  width: 221px;
  background-color: $bg;
  & span {
    @include addGradientToText(to left, $gr-landing);
  }

  &:hover {
    background-color: $bg;
  }
}

.buttonQR {
  width: 221px;
  border: 1px solid $bg-color;
  & span {
    color: $bg-color;
  }
  &:hover {
    border: 1px solid $bg-color;
  }
}
