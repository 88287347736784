.wrapper {
  position: relative;
  width: 375px;
  height: 565px;
  border-radius: 10px;
  color: #fff;
}

.container {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 100%;
  border-radius: 10px;
  padding: 0 16px 16px;
  background: linear-gradient(
    180deg,
    rgba(18, 18, 18, 0) 0.05%,
    #121212 67.13%
  );
}

.infoBlock {
  display: flex;
  justify-content: space-between;
  margin-bottom: 11px;
  z-index: 2;
}

.backgroundVideo {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1;
  border-radius: 10px;
}

.backgroundImage {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
