@use '/src/styles/colors.scss' as *;

$scrollbar-width: 6px;
$scrollbar-border-radius: 20px;

.backdrop {
  & div:global.MuiModal-backdrop {
    background-color: $HEX08080899;
    backdrop-filter: blur(4px);
  }
}

.wrapper {
  background-color: transparent;
  border-radius: 16px;

  scrollbar-width: thin;
  scrollbar-color: $default-gray-500 transparent;

  &::-webkit-scrollbar {
    width: $scrollbar-width;
    border-radius: $scrollbar-border-radius;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $default-gray-500;
    border-radius: $scrollbar-border-radius;
  }
}
