@use '/src//styles/colors.scss' as *;

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.accordions {
  display: flex;
  flex-direction: column;
  gap: 32px;
}
