.container {
  padding-top: 40px;
  width: 520px;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.wrapper {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.forgotPassword {
  padding-top: 6px;
  padding-left: 4px;
  align-self: start;
}

.submitButton {
  align-self: end;
}
