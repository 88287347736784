@use '/src/styles/colors.scss' as *;

.headerBlock {
  padding: 24px;
  padding-bottom: 28px;
  background-color: $default-gray-700;
  min-width: 200px;
}

.commentBlock {
  margin-top: 28px;
  display: flex;
  gap: 28px;
  align-items: flex-start;
}

.profileBlock {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex: 1 1 auto;
  margin-bottom: 12px;
  gap: 24px;
}

.image {
  max-width: 140px;
  width: 100%;
  height: 140px;
  object-fit: cover;
  border-radius: 6px;
}

.userImage {
  width: 36px;
  height: 36px;
  border-radius: 50%;
  object-fit: cover;
  color: $default-gray-200;
}

.main {
  background-color: $bg-color;
  padding: 24px;
  min-width: 500px;
}

.addReplyBlock {
  display: flex;
  gap: 12px;
}

.footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 12px;
}

.button {
  border-radius: 6px;
}

.iconsContainer {
  display: flex;
  align-items: center;
  gap: 8px;
  & button {
    padding: 6px;
  }
}

.formContent {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.images {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.media {
  flex-shrink: 0;
  width: 137px;
  height: 123px;
  object-fit: cover;
  border-radius: 6px;
}
