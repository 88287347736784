@use '/src/styles/colors.scss' as *;

.cardsContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 24px;

  @media (min-width: 1100px) {
    justify-content: flex-end;
  }
}

.cardWrapper {
  height: fit-content;
  padding: 20px 24px;
  border-radius: 16px;
  background: $bg-color;
}
