@use '/src/styles/mixins.scss' as flexbox;

.wrapper {
  @include flexbox.flexbox-center;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
}
