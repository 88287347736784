@use '/src/styles/colors.scss' as *;

.wrapper {
  width: 100%;
  border-radius: 16px;
  background-color: $bg-color;
  padding: 42px 0px;

  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 64px;
}

.statsBlock {
  display: flex;
  align-items: center;
  gap: 24px;
}

.chartContainer {
  width: 77px;
  height: 43px;
}
