@use '/src/styles/colors.scss' as *;

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.buttonText {
  background: linear-gradient(
    291deg,
    $landing-green1 -14.1%,
    $landing-green2 106.43%
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.scrollContainer {
  max-height: 325px;
  padding-right: 10px;
  display: flex;
  flex-direction: column;
  gap: 24px;
}
