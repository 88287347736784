@use "/src/styles/colors.scss" as *;

.flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.closeButton {
  width: 30px;
  height: 30px;
  padding: 13px;
  color: $HEXEBEBF599;
  background-color: $HEX2C2C2E;
  &:hover {
    background-color: $HEX2C2C2E;
  }
}
