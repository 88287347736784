.container {
  position: relative;
}

.map {
  height: 644px;
  border-radius: 16px;
  overflow: hidden;
}

.boardWrapper {
  position: absolute;
  top: 20px;
  left: 20px;
}

.zoomButtons {
  position: absolute;
  bottom: 25px;
  right: 20px;
  display: flex;
  gap: 10px;
}
