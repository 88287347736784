@use '/src/styles/colors.scss' as *;

.container {
  position: relative;
  max-width: 544px;
  display: flex;
  flex-direction: column;
  gap: 40px;
}

.fields {
  width: inherit;
  display: flex;
  flex-direction: column;
  gap: 20px;
}
