@use './colors.scss' as *;
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700;800;900&display=swap');
@import 'slick-carousel/slick/slick.css';
@import 'slick-carousel/slick/slick-theme.css';
@import 'react-circular-progressbar/dist/styles.css';

@font-face {
  font-family: 'Pragati Narrow';
  src: url('./fonts/PragatiNarrow-Regular.ttf') format('truetype');
  font-style: normal;
  font-weight: normal;
}

@font-face {
  font-family: 'Pragati Narrow';
  src: url('./fonts/PragatiNarrow-Bold.ttf') format('truetype');
  font-style: normal;
  font-weight: bold;
}

body {
  margin: 0;
  font-family: 'Poppins', sans-serif;
  background-color: $bg;
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

a {
  text-decoration: none;
  color: inherit;
}

ul li {
  list-style: none;
  font-size: inherit;
}

button,
input[type='submit'],
input[type='reset'] {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
}

.MuiList-padding {
  padding: 0;
}
