@use '/src/styles/colors.scss' as *;

.formControl {
  margin: 0;
  gap: 12px;
  padding-left: 2px;

  & span:global.Mui-checked {
    border: none;
    color: $HEXB8FE61;
  }
}

.checkbox {
  padding: 0;
  width: 20px;
  height: 20px;
  border-radius: 4px;
  border: 1px solid $default-gray-500;
  color: transparent;
  background-color: $default-gray-700;

  &:hover {
    background-color: $default-gray-700;
  }
}
