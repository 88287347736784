@use '/src/styles/colors.scss' as *;

.container {
  position: relative;
  width: 100%;
  background: $bg-color;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;

  @media (min-width: 1272px) {
    max-width: 600px;
  }
}
