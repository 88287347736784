@use '/src/styles/colors.scss' as *;
@use '/src/styles/variables.scss' as var;

.background {
  background-color: $bg;
  position: relative;
}

.container {
  max-width: var.$container + (var.$spacing24 * 2) + px;
  width: 100%;
  margin: 0 auto;
  padding-top: var.$headerHeight + px;
  padding-bottom: 66px;
  padding-left: var.$spacing24 + px;
  padding-right: var.$spacing24 + px;
}
