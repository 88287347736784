@use '/src/styles/colors.scss' as *;
@use '/src/styles/mixins.scss' as flexbox;

.wrapper {
  @include flexbox.flexbox-center;
  margin: 40px 0 48px;
  width: 100%;
  height: 193px;
  border-radius: 16px;
  background-color: $default-gray-700;
}

.container {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.title {
  color: $default-gray-200;
  text-transform: uppercase;
}

.balance {
  margin: 7px 0 20px;
  font-size: 30px;
  font-weight: 600;
  line-height: 36px;
}

.button {
  width: 200px;
  border-radius: 12px;
  background-color: $gr-for-buttons;
  cursor: pointer;
}
