@use '/src/styles/colors.scss' as *;

.wrapper {
  background-color: $bg-color;
  border-radius: 16px;
  padding: 20px;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.buttonsContainer {
  display: flex;
  align-items: center;
  gap: 10px;
}

.arrowButton {
  padding: 0;
  cursor: pointer;
}

.slider {
  & div:global.slick-slide {
    padding: 0 20px;
  }
  & div:global.slick-list {
    margin: 0 -20px;
  }
}

.viewGraphWrapper {
  height: 44px;
  margin-top: 24px;
}

.monthText {
  color: $default-gray-200;
  text-align: center;
  margin-top: 10px;
}
