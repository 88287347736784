@use '/src/styles/colors.scss' as *;

.wrapper {
  display: flex;
  justify-content: center;
  gap: 8px;
}

.percentBlock {
  display: flex;
  align-items: baseline;
  gap: 2px;
}

.triangle {
  width: 6px;
  height: 6px;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 8px solid $HEXB8FE61;

  &.negative {
    border-top: 6px solid $profile-red;
    border-bottom: 0;
  }
}

.percent {
  color: $HEXB8FE61;

  &.negative {
    color: $profile-red;
  }
}
