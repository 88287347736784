.wrapper {
  width: 274px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  gap: 8px;
}

.user {
  display: flex;
  align-items: center;
  gap: 8px;

  .image {
    border-radius: 30px;
    width: 30px;
    height: 30px;
  }
}

.place {
  display: flex;
  align-items: center;
  gap: 4px;
  width: 100%;
  padding: 4px;
  border-radius: 8px;
  background: rgba(255, 255, 255, 13%);

  img {
    border-radius: 7px;
  }
}
