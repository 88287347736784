@use '/src/styles/colors.scss' as *;

.buttonContainer,
.buttonContainer:hover {
  padding: 11px 24px;
  border-radius: 8px;
  border: 1px solid $default-gray-700;
  background: linear-gradient(
    291deg,
    $landing-green1 -14.1%,
    $landing-green2 106.43%
  );
}

.buttonContent {
  display: flex;
  align-items: center;
  gap: 8px;
}
