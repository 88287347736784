@use '/src/styles/colors.scss' as *;

.titleValue {
  color: $landing-green1;
}

.imageWrapper {
  margin-top: 24px;
}

.image {
  max-width: 1224px;
}
