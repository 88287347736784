@use '/src/styles/colors.scss' as *;

.field {
  width: 100%;

  & div:global.Mui-focused {
    background-color: $default-gray-700;
  }

  & button:global.MuiIconButton-root {
    color: $default-gray-200;
  }

  & label:global.MuiFormLabel-root {
    font-weight: 300;
    color: $default-gray-200;
  }
}

.input {
  font-weight: 300;
  border-radius: 12px;
  font-size: 14px;
  background-color: $default-gray-700;

  & > input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    caret-color: $white;
    -webkit-text-fill-color: $white;
    -webkit-background-clip: text !important;
  }

  &:hover {
    background-color: $default-gray-700;
  }

  & input::placeholder {
    color: $default-gray-700;
  }
}
